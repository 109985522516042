.Highlight .Highlight-top {
  border-bottom: 1px solid #d5d2d2;
  padding-bottom: 10px;
}
.Highlight .colorListName {
  margin-top: 20px;
  margin-bottom: 5px;
}
.Highlight .colorListName .name-key {
  font-size: 16px;
  font-weight: bold;
  margin-left: 40px;
}
.Highlight .colorListName .name-color {
  font-size: 16px;
  font-weight: bold;
  margin-left: 125px;
}
.Highlight .colorListName .name-add {
  float: right;
  bottom: 5px;
  right: 5px;
}
.Highlight .colorGroup .colorGroup-name {
  margin-right: 5px;
}
.Highlight .colorList {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: 70vh;
  overflow-y: auto;
}
.Highlight .colorList .colorCard {
  padding-bottom: 5px;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 10px;
}
.Highlight .colorList .colorCard .color-name {
  display: inline-block;
  width: 360px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dee2e6;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 6px;
  padding: 0 10px;
  font-size: 16px;
}
.Highlight .colorList .colorCard .color-col {
  display: inline-block;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dee2e6;
  margin-right: 10px;
  border-radius: 6px;
  padding: 0 10px;
  font-size: 16px;
}
.Highlight .colorList .colorCard .color-col .color-txt {
  display: inline-block;
}
.Highlight .colorList .colorCard .color-col .color-show {
  width: 20px;
  height: 20px;
  float: right;
  margin-top: 7px;
  display: inline-block;
}
