#webConsole {
  height: 100%;
}
#webConsole .header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  padding-left: 20px;
  background: #004ea2;
}
#webConsole .header h1 {
  color: #fff;
}
#webConsole .serial-left {
  padding: 15px;
  background-color: #f3f5f7;
}
#webConsole .serial-left #serial-status {
  width: 95%;
  height: 50px;
  background-color: #f8d7da;
  line-height: 50px;
  font-size: 20px;
  padding-left: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
#webConsole .serial-left .serial-select {
  width: 200px;
  margin-bottom: 20px;
}
#webConsole .serial-left .serial-select .option-title {
  font-size: 16px;
  margin-right: 8px;
}
#webConsole .leftDiv {
  display: flex;
  width: 20px;
  border: 1px solid #d7cece;
}
#webConsole .rightDiv {
  display: flex;
  width: 20px;
  border: 1px solid #d7cece;
}
#webConsole .serial-center {
  padding: 15px;
}
#webConsole .serial-center .center-title h1 {
  display: inline-block;
}
#webConsole .serial-center .center-title .center-topOption {
  font-size: 16px;
  float: right;
  display: flex;
  line-height: 40px;
}
#webConsole .serial-center .center-title .center-topOption .SubcontractingTime span {
  margin-right: 5px;
}
#webConsole .serial-center .center-title .SubcontractingTime {
  margin-right: 10px;
}
#webConsole .serial-center .center-title .log-select {
  font-size: 16px;
  margin-right: 8px;
}
#webConsole .serial-center .center-title .log-select .log-title {
  margin-right: 5px;
}
#webConsole .serial-center .serialLogs {
  overflow-y: scroll;
  white-space: pre-wrap;
  width: 100%;
  height: 70vh;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  margin-bottom: 10px;
  background: #000;
  color: #fff;
  padding: 5px;
}
#webConsole .serial-center .serialLogs .text-primary {
  display: inline-block;
  width: 140px;
}
#webConsole .serial-center .serialLogs .text-success {
  display: inline-block;
  width: 140px;
}
#webConsole .serial-center .sending-option .sending-textarea {
  width: 100%;
  border-radius: 5px;
}
#webConsole .serial-center .sending-option .center-bottom {
  margin-top: 10px;
}
#webConsole .serial-center .sending-option .center-bottom .send-option {
  float: left;
  display: flex;
  align-items: center;
  margin-right: 30px;
}
#webConsole .serial-center .sending-option .center-bottom .send-option .SendingInterval span {
  margin-right: 5px;
}
#webConsole .serial-center .sending-option .center-bottom .check-options {
  display: inline-block;
}
#webConsole .serial-center .sending-option .center-bottom .sendBtn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
#webConsole .serial-right {
  padding: 0 15px;
  background-color: #f3f5f7;
}
#webConsole .ant-row {
  flex-flow: nowrap;
}
#webConsole .ant-tabs-nav-wrap {
  border-bottom: 1px solid #d7cece;
}
