.ShortcutInstructions .instructionsGroup .instructionsGroup-name {
  margin-right: 5px;
}
.ShortcutInstructions .instructionsBtn {
  margin-top: 10px;
  margin-bottom: 10px;
}
.ShortcutInstructions .instructionsBtn .btn-add {
  float: right;
  right: 10px;
}
.ShortcutInstructions .instructionsListName {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}
.ShortcutInstructions .instructionsListName .name-batch {
  margin-right: 10px;
}
.ShortcutInstructions .instructionsListName .name-text {
  margin-right: 40px;
}
.ShortcutInstructions .instructionsListName .name-add {
  float: right;
  bottom: 5px;
  right: 5px;
}
.ShortcutInstructions .instructionsList {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: 20vh;
  overflow-y: auto;
}
.ShortcutInstructions .instructionsList .instructionsCard {
  padding-bottom: 5px;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 10px;
}
.ShortcutInstructions .instructionsList .instructionsCard .instructions-name {
  display: inline-block;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dee2e6;
  margin-right: 10px;
  border-radius: 6px;
  padding: 0 10px;
  font-size: 16px;
}
.ShortcutInstructions .instructionsList .instructionsCard .instructions-cont {
  display: inline-block;
  width: 100px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #dee2e6;
  margin-right: 10px;
  border-radius: 6px;
  padding: 0 10px;
  font-size: 16px;
}
.ShortcutInstructions .instructionsList .instructionsCard .instructions-send {
  margin-right: 5px;
}
.ShortcutInstructions .listBox {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: 75vh;
}
