.CloudsView .deviceList {
  margin-bottom: 5px;
}
.CloudsView .deviceList .deviceBtn {
  float: right;
  bottom: 5px;
}
.CloudsView .device-status {
  width: 95%;
  height: 50px;
  background-color: #f8d7da;
  line-height: 50px;
  font-size: 20px;
  padding-left: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
