.user {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user .user-content {
  width: 500px;
  height: 360px;
  border: 1px solid #dfdddd;
  padding: 20px;
}
.user .user-content .user-title {
  font-size: 30px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: Regular;
  text-align: center;
  color: #666666;
  display: block;
  height: 80px;
  margin-top: 20px;
}
.user .user-content .login-form-button {
  width: 395px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFang, PingFang-Bold;
  /* font-weight: 700; */
  text-align: center;
  color: #ffffff;
  opacity: 0.96;
  font-size: 20px;
  line-height: 28px;
  position: relative;
  top: 0px;
  left: 30px;
}
