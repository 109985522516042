.header {
  width: 100%;
  height: 70px;
  line-height: 70px;
  padding-left: 20px;
  background: #004ea2;
  position: relative;
}
.header h1 {
  color: #fff;
}
.header .userInfo {
  width: 150px;
  height: 50px;
  position: absolute;
  top: 10px;
  right: 20px;
}
.header .userInfo .user-title {
  font-size: 28px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: Regular;
  text-align: left;
  color: #fff;
  text-align: center;
  line-height: 50px;
}
.header .shezhi {
  color: #fff;
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: Regular;
  text-align: left;
  position: absolute;
  top: 0px;
  left: 280px;
}
.shiLiBox {
  width: 270px;
  height: 80px;
  background-color: black;
  margin-bottom: 15px;
  border: 1px solid #333;
}
